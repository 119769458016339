import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import PageServiceMemo from 'Pages/service-memo';

const ServiceMemo = ({ match }) => (
	<div className="dashboard-wrapper">
		<Switch>
			<Route exact path={`${match.url}/:page`} component={ PageServiceMemo } />
			<Redirect exact from={ match.url } to={`${match.url}/salesman`} />
			<Redirect to="/error" />
		</Switch>
	</div>
);
export default ServiceMemo;